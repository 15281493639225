import { connect } from 'react-redux';

import { passwordReset } from 'Redux/modules/user/actions';

import Component from './component';

export default connect(
    state => ({
        user: state.user,
    }), 
    dispatch => ({
        actions: {
            passwordReset: dispatch(passwordReset),
        },
    })
)(Component);

