import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import {
    PUBLIC_USER_LOGIN,
} from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import PasswordResetForm from 'Components/publicUser/PasswordResetForm';

export default class PublicPasswordReset extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    state = {
        isSuccess: false,
    };

    onSuccess = () => this.setState({ isSuccess: true });

    render() {
        const { isSuccess } = this.state;

        return (
            <StyledComponent
                styles={require('./styles')}
                className="component-password-reset-page"
            >
                <LayoutContainer>
                    <div className="section-container">
                        <div className="logo">
                            <img src={require('Theme/images/logo-color-black.png')} />
                        </div>
                        {isSuccess
                            ?
                            <>
                                <h2 className="section-title small">Na podany adres e-mail wysłaliśmy wiadomość. Postępuj zgodnie ze wskazówkami.</h2>
                            </>
                            :
                            <>
                                <h2 className="section-title">Przypomnij hasło</h2>
                                <PasswordResetForm onSuccess={this.onSuccess}/>
                            </>
                        }
                        <p className="section-disclaimer">
                            <NavLink className="link" to={PUBLIC_USER_LOGIN.path}>
                                Powrót do logowania
                            </NavLink>
                        </p>
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
