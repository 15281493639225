import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ERROR_MSG_CRITICAL } from 'Consts/errors';
import Logger from 'Services/Logger';

import StyledComponent from 'Components/core/StyledComponent';
import Form from 'Components/forms/Form';
import Button from 'Components/layout/Button';
import Input from 'Components/forms/Input';

export default class PasswordResetFormForm extends Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        actions: PropTypes.shape({
            passwordReset: PropTypes.func.isRequired,
        }).isRequired,
        buttonText: PropTypes.string,
    };
    static defaultProps = {
        onSuccess: () => true,
        buttonText: 'Przypomij hasło',
    };
    state = {
        globalError: null,
        errors: {},
        isPending: false,
        formState: {
            email: null,
        },
    };

    onSubmit = () => {
        const { formState } = this.state;
        const { actions } = this.props;

        this.setState({ isPending: true });

        return actions.passwordReset({
            email: formState.email,
        })
            .then(response => {
                this.onSuccess(response);
                this.setState({ isPending: false });
            })
            .catch(error => {
                Logger.error('[RESET_PASSWORD_FORM] Critical Error', error);
                this.setState({
                    isPending: false,
                    globalError: ERROR_MSG_CRITICAL,
                });
            });
    }

    onSuccess = response => {
        const { onSuccess } = this.props;
        return onSuccess(response);
    }

    onStateChange = (name, value) => this.setState(prevState => ({
        formState: {
            ...prevState.formState,
            [name]: value,
        },
    }))

    render() {
        const { formState, globalError, isPending } = this.state;
        const { buttonText } = this.props;

        return (
            <StyledComponent
                styles={require('./styles')}
                className="component-password-reset-form"
            >
                <Form
                    data={formState}
                    onStateChange={this.onStateChange}
                    error={null}
                    onSubmit={this.onSubmit}
                    isPending={isPending}
                    globalError={globalError}
                >
                    <Input
                        label="E-mail"
                        name="email"
                        placeholder="Wprowadź adres email"
                        type="email"
                        styleVersion={2}
                    />
                    <Button
                        className="submit-button"
                        type="submit"
                        icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                        layout="fullWidth"
                        size="large"
                        style="gradient"
                    >
                        {buttonText}
                    </Button>
                </Form>
            </StyledComponent>
        );
    }
}
